import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { login as apiLogin, requestPasswordReset, resetPassword } from '../api/auth/authApi';
import { AppDispatch } from '.';
import { LoginFormData } from '../components/LoginModal';
import { showNotification } from './notificationSlice';
import { getConfiguration } from './configSlice';
import { DivisionResponseDto, FeedDto, UserDivisionDto, UserSeasonDto } from '../api/apiTypes';
import { activeUsers, addUsersToDivisions, addUsersToSeasons, fetchUserDivisions, getFeedInfo, getUsers } from '../api/user/usersApi';
import { AxiosError } from 'axios';
import { stat } from 'fs';

interface UsersState {
  loading: boolean;
  isAuthenticated: boolean;
  error: string | null;
  userId: string | null;
  nickname: string | null;
  roles: string[] | null;
  feedInformation: FeedDto | null;
  userDivisions: DivisionResponseDto[] | null;
}

const initialState: UsersState = {
  loading: false,
  isAuthenticated: false,
  error: null,
  userId: null,
  nickname: null,
  roles: null,
  feedInformation: null,
  userDivisions: null,
};

const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    loginSuccess: (state, action: PayloadAction<{ token: string, userId: string, roles: string[], nickname: string }>) => {
      state.isAuthenticated = true;
      state.userId = action.payload.userId
      state.nickname = action.payload.nickname
      localStorage.setItem('token', action.payload.token)
      state.roles = action.payload.roles
      localStorage.setItem('roles', JSON.stringify(action.payload.roles))
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      localStorage.removeItem('token');
      state.isAuthenticated = false;
      state.userId = null;
      state.nickname = null;
      state.error = action.payload;
      state.roles = null
      localStorage.removeItem('roles')
    },
    getFeedSuccess: (state, action: PayloadAction<FeedDto>) => {
      state.feedInformation = action.payload;
    },
    getFeedFailure: (state, action: PayloadAction<string>) => {
      state.feedInformation = null;
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      localStorage.removeItem('token');
      state.userId = null;
      state.nickname = null;
      state.roles = null
      localStorage.removeItem('roles')
    },
    getUserDivisionsSuccess: (state, action: PayloadAction<DivisionResponseDto[]>) => {
      state.userDivisions = action.payload;
    },
    getUserDivisionsFailure: (state) => {
      state.userDivisions = null;
    },
    requestPasswordResetSuccess: (state, action: PayloadAction<string>) => {
      state.error = null;
    },
    requestPasswordResetFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetPasswordSuccess: (state, action: PayloadAction<string>) => {
      state.error = null;
    },
    resetPasswordFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    }
  },
});

export const { setLoading, loginSuccess, loginFailure, logoutSuccess, getFeedSuccess, getFeedFailure, getUserDivisionsSuccess, getUserDivisionsFailure, requestPasswordResetFailure, requestPasswordResetSuccess, resetPasswordFailure, resetPasswordSuccess } = usersSlice.actions;

export const login = (data: LoginFormData) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await (await apiLogin(data.email, data.password))(dispatch); // Call the imported login function
    dispatch(loginSuccess(response));
    dispatch(getConfiguration());
    dispatch(showNotification({ severity: 'success', summary: 'Inicio de sesión exitoso', detail: 'Has iniciado sesión correctamente' }));
  } catch (error) {
    let errorMessage = 'Ocurrió un error inesperado.';

    if (error instanceof AxiosError) {
      if (error.response) {
        if (Array.isArray(error.response.data) && error.response.data[0]) {
          errorMessage = error.response.data[0].description || 'Ocurrió un error al procesar tu solicitud.';
        } else if (typeof error.response.data === 'string') {
          errorMessage = error.response.data;
        } else {
          if (error.response.status === 401) {
            dispatch(loginFailure('Correo electrónico o contraseña inválidos'));
            dispatch(showNotification({ severity: 'error', summary: 'Ocurrió un error al iniciar sesión.', detail: error.response.data.message.toString(), life: 8000 }));
            return;
          } else if (error.response.status === 403) {
            dispatch(loginFailure('Cuenta aún no está activada'));
            dispatch(showNotification({ severity: 'warn', summary: 'Tu cuenta aún no está activada.', detail: error.response.data.message.toString(), life: 8000 }));
            return;
          } else {
            errorMessage = error.response.data.description || 'Ocurrió un error al procesar tu solicitud.';
          }
        }
      } else if (error.request) {
        errorMessage = 'No se pudo establecer conexión con el servidor. Verifica tu conexión a internet.';
      } else {
        errorMessage = error.message;
      }
    }
    dispatch(loginFailure('Correo electrónico o contraseña inválidos'));
    dispatch(showNotification({ severity: 'error', summary: 'Ocurrió un error al iniciar sesión.', detail: errorMessage.toString(), life: 8000 }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getFeed = (userId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getFeedInfo(userId);
    dispatch(getFeedSuccess(response))
  } catch (error) {
    dispatch(getFeedFailure('Error fetching feed information'));
  } finally {
    dispatch(setLoading(false));
  }
};

export const logout = () => (dispatch: AppDispatch) => {
  dispatch(logoutSuccess());
};

export const getUserDivisions = (userId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchUserDivisions(userId);
    dispatch(getUserDivisionsSuccess(response))
  } catch (error) {
    dispatch(getUserDivisionsFailure())
  } finally {
    dispatch(setLoading(false));
  }
}

export const fetchUsers = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getUsers();
    return response;
  } catch (error) {
    console.log(error);
  }
  finally {
    dispatch(setLoading(false));
  }
};

export const fetchAddUsersToSeasons = (usersSeasons: UserSeasonDto[]) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await addUsersToSeasons(usersSeasons);
    return response;
  } catch (error) {
    console.log(error);
  }
  finally {
    dispatch(setLoading(false));
  }
};

export const fetchActiveUsers = (userIds: string[]) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await activeUsers(userIds);
    return response;
  } catch (error) {
    console.log(error);
  }
  finally {
    dispatch(setLoading(false));
  }
};


export const fetchAddUsersToDivisions = (usersSeasons: UserDivisionDto[]) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await addUsersToDivisions(usersSeasons);
    return response;
  } catch (error) {
    console.log(error);
  }
  finally {
    dispatch(setLoading(false));
  }
};

export const requestPasswordResetAction = (email: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await requestPasswordReset(email);
    dispatch(requestPasswordResetSuccess(response.message));
    dispatch(showNotification({ severity: 'success', summary: 'Solicitud de restablecimiento', detail: 'Se ha enviado el enlace de restablecimiento de contraseña a tu correo.' }));
  } catch (error) {
    let errorMessage = 'Ocurrió un error al solicitar el restablecimiento de contraseña.';

    if (error instanceof AxiosError && error.response) {
      errorMessage = error.response.data.message || errorMessage;
    }
    dispatch(requestPasswordResetFailure(errorMessage));
    dispatch(showNotification({ severity: 'error', summary: 'Error', detail: errorMessage, life: 8000 }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const resetPasswordAction = (email: string, token: string, newPassword: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await resetPassword(email, token, newPassword);
    dispatch(resetPasswordSuccess(response.message));
    dispatch(showNotification({ severity: 'success', summary: 'Contraseña restablecida', detail: 'Tu contraseña ha sido restablecida con éxito.', life: 6000 }));
  } catch (error) {
    let errorMessage = 'Ocurrió un error al restablecer la contraseña.';

    if (error instanceof AxiosError && error.response) {
      errorMessage = error.response.data.message || errorMessage;
    }
    dispatch(resetPasswordFailure(errorMessage));
    dispatch(showNotification({ severity: 'error', summary: 'Error', detail: errorMessage, life: 8000 }));
  } finally {
    dispatch(setLoading(false));
  }
};

export default usersSlice.reducer;
