import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CancelMatchDto, MatchResponseDto } from '../api/apiTypes';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { cancelMatch, fetchMatchDetails, joinMatch, removeFromMatch } from '../store/matchesSlice';
import { isWithinNext24Hours } from '../helpers/dateCompare';
import MatchFinishedCard from '../components/MatchFinishedCard';
import { useNavigate } from 'react-router-dom';
import 'moment/locale/es';
import MatchMainInfo from '../components/MatchMainInfo';
import MatchPlayersInfo from '../components/MatchPlayersInfo';
import MatchExtraInfo from '../components/MatchExtraInfo';
import './styles/MatchInfo.css'
import { showNotification } from '../store/notificationSlice';
import moment from 'moment';

const MatchInfo: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { matchId } = useParams<{ matchId: string }>();
  const userId = useSelector((state: RootState) => state.users?.userId);
  const [match, setMatch] = useState<MatchResponseDto | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const getMatchDetailAsync = useCallback(async () => {
    try {
      if (matchId !== undefined) {
        setIsLoading(true)
        const matchDetails = await dispatch(fetchMatchDetails(matchId, userId as string));
        if (matchDetails) {
          if (matchDetails.matchState !== 'Cancelled') {
            setMatch(matchDetails);
          }
          else {
            navigate('/available-matches');
            dispatch(showNotification({
              severity: 'warn',
              summary: 'Partido cancelado',
              detail: 'El partido que intentabas ver se encuentra cancelado',
              sticky: false,
              life: 5000,
            }));
          }
        }
      }
    } catch (error) {
      console.log('An error has occurred fetching the match')
    } finally {
      setIsLoading(false)
    }
  }, [dispatch, matchId]);

  const userAlreadyJoined = useMemo(() => {
    return match?.userIsJoined;
  }, [userId, match]);

  const showPlayers = match?.matchState === 'Closed' && isWithinNext24Hours(match?.date as string) && match?.spotsAvailable === 0;

  useEffect(() => {
    getMatchDetailAsync();
  }, [getMatchDetailAsync]);

  const onAddMatch = async () => {
    if (matchId && userId) {
      try {
        await dispatch(joinMatch(matchId, userId));
        await getMatchDetailAsync();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onRemoveFromMatch = async () => {
    if (matchId && userId) {
      try {
        await dispatch(removeFromMatch(matchId, userId));
        await getMatchDetailAsync();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onCancelMatch = async () => {
    if (matchId && userId) {
      try {
        const cancelMatchDto: CancelMatchDto = {
          matchId: matchId,
          userId: userId,
        };
        await dispatch(cancelMatch(cancelMatchDto));
        navigate('/available-matches');
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formattedDate = match ? moment.utc(match.date).local().format('dddd DD-MMM') : '';
  const formattedTime = match ? moment.utc(match.date).local().format('HH:mm') : '';
  const shareMessage = `Nuevo partido disponible!\n📅 ${formattedDate}\n⌚ ${formattedTime}\n📍 ${match?.clubName}\n🔗 ${window.location.href}`;

  return (
    <div className="flex justify-content-center align-items-center flex-column" >
      <div className='match-info-container'>
        <div className='my-2'><MatchMainInfo isLoading={isLoading} match={match as MatchResponseDto} /></div>
        {match?.matchState !== 'Finalized' ? (
          <MatchPlayersInfo
            isLoading={isLoading}
            spotsAvailable={match?.spotsAvailable as number}
            userIsJoined={userAlreadyJoined}
            showDisclaimer={!showPlayers}
            showPlayers={showPlayers}
            showCancelMatch={match?.creatorId === userId && match.spotsAvailable !== 0}
            onJoinMatch={onAddMatch}
            onUserRemovesFromMatch={onRemoveFromMatch}
            onCancelMatch={onCancelMatch}
            player1={match?.player1}
            player2={match?.player2}
            player3={match?.player3}
            player4={match?.player4}
            shareMessage={shareMessage}
          />
        ) : null}
        <div className='my-2'><MatchExtraInfo isLoading={isLoading} match={match as MatchResponseDto} /></div>
        <div>{match && match?.matchState === 'Finalized' ? <MatchFinishedCard match={match as MatchResponseDto} /> : ''}</div>
      </div>
    </div >
  );
};

export default MatchInfo;
