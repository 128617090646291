import React from 'react';
import { blue } from '../../constants/colors';
import { UserIdNameDto } from '../../api/apiTypes';
import CompleteSpot from './CompleteSpot';
import EmptySpot from './EmptySpot';
import UserAlreadyJoined from './UserAlreadyJoined';
import Player from './Player';

export type PlayersPairTheme = 'blue' | 'white'

type PlayersPairsProps = {
    isInCarousel?: boolean;
    theme: PlayersPairTheme;
    spotsAvailable: number;
    userIsJoined?: boolean;
    userCanCancel?: boolean;
    showPlayers?: boolean;
    player1?: UserIdNameDto;
    player2?: UserIdNameDto;
    player3?: UserIdNameDto;
    player4?: UserIdNameDto;
    onJoinMatch: () => void;
    onUserRemovesFromMatch: () => void;
};

const PlayersPairs: React.FC<PlayersPairsProps> = (props) => {
    const { isInCarousel = false, theme, spotsAvailable, userIsJoined, userCanCancel = false, showPlayers, player1, player2, player3, player4, onJoinMatch, onUserRemovesFromMatch } = props;
    const renderSpots = () => {
        const spots = [];
        let completeSpots = userIsJoined ? 4 - (spotsAvailable + 1) : 4 - spotsAvailable;
        let userSpotRendered = false;
        for (let i = 0; i < 4; i++) {
            if (i === 2) {
                // Add the separator always in the middle
                spots.push(<div key="separator" style={{ height: 70, width: 2, backgroundColor: theme === 'blue' ? '#FFFF' : blue, margin: '0 10px' }} />);
            }
            if (completeSpots > 0) {
                spots.push(
                    <React.Fragment key={`complete-${i}`}>
                        <CompleteSpot theme={theme} isInCarousel={isInCarousel} />
                    </React.Fragment>
                );
            } else if (userIsJoined && !userSpotRendered) {
                spots.push(
                    <React.Fragment key={`user-${i}`}>
                        <UserAlreadyJoined onUserRemovesFromMatch={onUserRemovesFromMatch} theme={theme} isInCarousel={isInCarousel} canCancel={!userCanCancel} />
                    </React.Fragment>
                );
                userSpotRendered = true;
            }
            else {
                spots.push(<React.Fragment key={`empty-${i}`}>{<EmptySpot userIsJoined={userIsJoined !== undefined ? userIsJoined : false} onJoinMatch={onJoinMatch} isInCarousel={isInCarousel} />}</React.Fragment>);
            }
            completeSpots--;
        }
        return spots;
    };

    const renderPlayers = () => {
        if (player1 && player1 !== undefined && player2 && player2 !== undefined && player3 && player3 !== undefined && player4 && player4 !== undefined) {
            return (
                <>
                    <Player player={player1} theme={theme} />
                    <Player player={player4} theme={theme} />
                    <div key="separator" style={{ height: 70, width: 2, backgroundColor: theme === 'blue' ? '#FFFF' : blue, margin: '0 10px' }} />
                    <Player player={player2} theme={theme} />
                    <Player player={player3} theme={theme} />
                </>
            )
        }
    }

    return (
        <div className='grid align-items-center p-3' style={{ display: 'flex', alignItems: 'center' }}>
            {showPlayers ? renderPlayers() : renderSpots()}
        </div>
    );
};

export default PlayersPairs;
