import React from 'react';
import { Badge } from 'primereact/badge';
import { Skeleton } from 'primereact/skeleton';
import { MatchResponseDto } from '../api/apiTypes';
import './styles/MatchCard.css';
import { useNavigate } from 'react-router-dom';
import { divisionName } from '../store/configSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import moment from 'moment-timezone';
import 'moment/locale/es';
import PlayersPairs from './Players/PlayersPairs';
import { clear_grey } from '../constants/colors';
import { isWithinNext24Hours } from '../helpers/dateCompare';

type MatchCardProps = {
  match: MatchResponseDto;
  isLoading?: boolean;
  isInCarousel?: boolean;
};

const MatchCard: React.FC<MatchCardProps> = ({ match, isLoading, isInCarousel = false }) => {
  const navigate = useNavigate();

  const { date } = match;
  const formattedDate = match ? moment.utc(date).local().format('dddd DD-MMM') : '';
  const formattedTime = match ? moment.utc(date).local().format('HH:mm') : '';
  const divisionLabel = useSelector((state: RootState) => divisionName(match.divisionId)(state));
  const formattedCourtPrice = new Intl.NumberFormat('es-UY', { style: 'decimal', minimumFractionDigits: 0 }).format(Number(match?.courtPrice));

  const handleCardClick = () => {
    if (match.pendingToLoadResult) {
      navigate(`/match-load-result/${match.id}`);
    } else {
      navigate(`/match-info/${match.id}`);
    }
  };

  const showPlayers = match?.matchState === 'Closed' && isWithinNext24Hours(match?.date as string) && match?.spotsAvailable === 0;

  return isLoading ? (
    <MatchCardSkeleton />
  ) : (
    <div onClick={handleCardClick}>
      <div className="division-name">
        <Badge size={'large'} className="division-badge" value={divisionLabel?.toLocaleUpperCase()} />
      </div>
      <div className="results">
        <div className="results-container">
          <span className='font-bold text-l text-center capitalize'>{`${formattedDate} | ${formattedTime}`}</span>
          <PlayersPairs theme={'white'}
            spotsAvailable={match.spotsAvailable}
            userIsJoined={match.userIsJoined}
            showPlayers={showPlayers}
            player1={match.player1}
            player2={match.player2}
            player3={match.player3}
            player4={match.player4}
            isInCarousel={isInCarousel}
            //TODO: See how to do this.
            onJoinMatch={function (): void {
              throw new Error('Function not implemented.');
            }}
            onUserRemovesFromMatch={function (): void {
              throw new Error('Function not implemented.');
            }} />
          <div className="custom-divider" />
          <div className="grid align-items-center">
            <div className="col-9">
              <div className="font-bold text-l capitalize">{match.clubName}</div>
              <div className="text-m capitalize white-space-nowrap overflow-hidden text-overflow-ellipsis" style={{ width: '220px' }}>{match.clubAddress}</div>
            </div>
            <div className="col-3">
              <div
                style={{
                  backgroundColor: clear_grey,
                  borderRadius: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  padding: '10px 0',
                }}
              >
                <div className="sm:font-bold text-sm sm:text-l capitalize">{`$ ${formattedCourtPrice}`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//TODO: Improve this.
const MatchCardSkeleton: React.FC = () => {
  return (
    <div className="match-card">
      <div className="border-round border-1 surface-border p-4 surface-card">
        <div className="flex mb-3">
          <Skeleton width="20rem" className="mb-2"></Skeleton>
        </div>
        <Skeleton width="100%" height="150px"></Skeleton>
        <div className="flex justify-content-between mt-3">
          <Skeleton width="4rem" height="2rem"></Skeleton>
          <Skeleton width="4rem" height="2rem"></Skeleton>
        </div>
      </div>
    </div>
  );
};

export default MatchCard;
