import React from 'react';
import { green, grey } from '../../constants/colors';
import { confirmPopup } from 'primereact/confirmpopup';
import './players.css'


type EmptySpotProps = {
    isInCarousel?: boolean;
    userIsJoined: boolean;
    fontColor?: string;
    onJoinMatch: () => void;
};

const EmptySpot: React.FC<EmptySpotProps> = ({ isInCarousel, userIsJoined, fontColor = "#FFFFFF", onJoinMatch }) => {

    const onJoinMatchClick = (event: any) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Estas seguro que quieres unirte al partido?',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => onJoinMatch(),
            acceptLabel: 'Si',
            dismissable: true
        });
    };

    const textSize = isInCarousel ? 'text-5xl' : 'text-6xl'

    return (
        <div className='customCol col cursor-pointer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={userIsJoined ? undefined : onJoinMatchClick}>
            <div className={`pi pi-plus-circle ${textSize}`} style={{ color: green }} />
            <div className='text-m font-bold' style={{ color: green }}>{'Libre'}</div>
        </div>
    );
};

export default EmptySpot;
