import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRanking } from '../store/rankingSlice';
import { AppDispatch, RootState } from '../store';
import { Ranking as RankingType, SeasonResponseDto, DivisionResponseDto, RankingPlayer } from '../api/apiTypes';
import './styles/Ranking.css'
import { blue } from '../constants/colors';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';

const Ranking: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const config = useSelector((state: RootState) => state.configuration);
  const loading = useSelector((state: RootState) => state.ranking.loading);
  const ranking = useSelector((state: RootState) => state.ranking.ranking);
  const navigate = useNavigate();
  const firstDivision = config.divisions?.[0];
  const firstSeason = config.seasons?.[0];
  const [selectedSeason, setSelectedSeason] = useState<SeasonResponseDto | undefined>(firstSeason);
  const [selectedDivision, setSelectedDivision] = useState<DivisionResponseDto | undefined>(firstDivision);

  useEffect(() => {
    const getRanking = async () => {
      if (selectedDivision?.id && selectedSeason?.id) {
        await dispatch(fetchRanking(selectedDivision.id, selectedSeason.id));
      }
    };
    getRanking();
  }, [selectedSeason, selectedDivision, dispatch]);

  const renderRows = () => {
    if (ranking == null || ranking.players == undefined || ranking.players.length == 0) {
      return (
        <td colSpan={6} className='align-items-center'>No se han encontrado resultados.</td>
      )
    }
    else {
      return ranking.players.map((player: RankingPlayer, index: number) => (
        <tr key={player.playerId} style={{ backgroundColor: !player.hasMinimumMatches ? '#fcd4d2' : "" }}>
          <td className="position-cell">{index + 1}</td>
          <td>
            <div className="flex align-items-center gap-2" onClick={(event) => { event.stopPropagation(); navigate(`/user-information/${player.playerId}`) }} style={{ cursor: 'pointer' }}>
              <img alt={player.nickname} src={process.env.REACT_APP_STORAGE_URL + player.playerId.toLowerCase()} width="32" style={{ borderRadius: '50%', width: '28px', height: '28px', objectFit: 'cover' }} />
              <span className="font-bold">{player.nickname}</span>
              {index === 0 && <span className="crown-icon">👑</span>}
            </div>
          </td>
          <td className="victory-cell">{player.matchesWonPercentage}</td>
          <td className="victory-cell">{player.setsWonPercentage}</td>
          <td className="victory-cell">{player.matchesPlayed}</td>
        </tr>
      ))
    }

  }

  return (
    <div className="feed-container">
      <div style={{ maxWidth: 1200, flex: 1 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: blue,
              padding: 10,
              borderRadius: 10,
              flex: 1,
            }}>
            <div
              className="text-2xl font-bold pb-3"
              style={{ color: '#BDE83F', fontFamily: 'TTRunsBold' }}
            >
              Posiciones
            </div>
            <div className="flex flex-row flex-wrap justify-content-center align-items-center gap-3"></div>

            <div className="grid grid-nogutter" style={{ borderRadius: 8, paddingInline: 6, marginBlock: 8 }}>
              <div className="col-4 flex align-items-center justify-content-start">
                <span className="text-xl" style={{ color: '#BDE83F' }}>
                  Temporada
                </span>
              </div>
              <div className="col">
                <Dropdown
                  value={selectedSeason}
                  onChange={(e: DropdownChangeEvent) => setSelectedSeason(e.value)}
                  options={[...(config.seasons as [])]}
                  optionLabel="name"
                  placeholder="Temporada"
                  className="w-full md:w-14rem my-2 filters-dropdown"
                  checkmark={true}
                  highlightOnSelect={false}
                />
              </div>
            </div>
            <div className="grid grid-nogutter" style={{ borderRadius: 8, paddingInline: 6, marginBlock: 8 }}>
              <div className="col-4 flex align-items-center justify-content-start">
                <span className="text-xl" style={{ color: '#BDE83F' }}>
                  Categoría
                </span>
              </div>
              <div className="col">
                <Dropdown
                  value={selectedDivision}
                  onChange={(e: DropdownChangeEvent) => setSelectedDivision(e.value)}
                  options={[...(config.divisions as [])]}
                  optionLabel="name"
                  placeholder="Categoría"
                  className="w-full md:w-14rem my-2 filters-dropdown"
                  checkmark={true}
                  highlightOnSelect={false}
                />
              </div>
            </div>
          </div>
        </div>
        {
          (ranking != null) ?
            <p style={{ fontWeight: 'bold', color: '#dc3545', textAlign: 'center', margin: '10px' }} className="text-s">
              IMPORTANTE: Necesitas por lo menos {`${ranking?.minimumMatches}`} partidos jugados para tener una posición valida en la tabla.
            </p>
            : ""
        }
        <div className="table-container" style={{ scrollbarWidth: 'none' }}>
          <table className="ranking-table">
            <thead>
              <tr style={{ borderTopRightRadius: 12 }}>
                <th className="position-header">#</th>
                <th>NOMBRE</th>
                <th>GAN(%)</th>
                <th>SETS(%)</th>
                <th>PJ</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={3} className="loading">Cargando...</td>
                </tr>
              ) : (
                renderRows()
              )}
            </tbody>
          </table>
        </div>
      </div>

    </div>
  );
};

export default Ranking;