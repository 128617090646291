import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { Button } from 'primereact/button';
import './styles';
import { FormInput } from '../components';
import { addMatch } from '../store/matchesSlice';
import { useNavigate } from 'react-router-dom';
import { generateTimeOptions } from '../helpers/timeOptions';
import { getUserDivisions } from '../store/usersSlice';
import { blue } from '../constants/colors';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { getConfiguration } from '../store/configSlice';

export type MatchData = {
  date: string;
  time: string;
  clubId: string;
  courtName: string;
  divisionId: number;
  courtPrice: string;
  creatorId: string;
  customClubName?: string;
  customClubAddress?: string;
};

const AddMatch: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,  // Watch the value of clubId
  } = useForm<MatchData>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.users);
  const configuration = useSelector((state: RootState) => state.configuration);

  // Watch the clubId value
  const selectedClubId = watch('clubId');

  useEffect(() => {
    const getUserDivisionsAsync = async () => {
      await dispatch(getUserDivisions(user.userId as string));
      await dispatch(getConfiguration());
    };
    getUserDivisionsAsync();
  }, [dispatch, user.userId]);

  const onSubmit = async (data: MatchData) => {
    // Extract hours and minutes from time
    const [hours, minutes] = data.time.split(':').map(Number);

    // Create a new date object using the date string
    const localDate = new Date(data.date);
    localDate.setHours(hours, minutes, 0, 0);

    const transformedData: MatchData = {
      ...data,
      date: localDate.toISOString(),
      creatorId: user.userId as string,
      courtPrice: data.courtPrice.toString(),
    };
    await dispatch(addMatch(transformedData));
    navigate('/available-matches');
  };

  const confirmCreation = (data: MatchData) => {
    confirmDialog({
      message: 'Al crear este partido, confirmas que eres la persona que reservó la cancha y te comprometes a cancelarla en caso de que el partido no se complete. Aceptas ser responsable de la gestión de la reserva?',
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      defaultFocus: 'accept',
      acceptLabel: 'Si',
      accept: () => onSubmit(data)
    });
  }

  const clubOptions = useMemo(() => {
    return configuration.clubs !== undefined && configuration.clubs?.length > 0
      ? configuration.clubs.map(club => ({ label: club.name, value: club.id }))
      : [];
  }, [configuration]);

  const divisionsOptions = useMemo(() => {
    return user.userDivisions !== null && user.userDivisions?.length > 0
      ? user.userDivisions.map(division => ({ label: division.name, value: division.id }))
      : [];
  }, [user.userDivisions]);

  const timeOptions = useMemo(generateTimeOptions, []);

  return (
    <div className="form-container">
      <div className="text-3xl font-bold pb-3" style={{ color: blue, fontFamily: 'TTRunsBold' }}>
        Crea un partido
      </div>
      <div className="text-m font-bold">
        Agrega los datos de la cancha reservada y nosotros nos encargaremos del resto.
      </div>
      <p className="important-note font-bold">RECORDATORIO: El sistema no se encarga de reservar la cancha.</p>
      <form onSubmit={handleSubmit(confirmCreation)} className="p-fluid">
        <div className="p-grid p-fluid row gap-4">
          <div className="flex-1">
            <FormInput name="date" control={control} label="Fecha" type="date" defaultValue={null} rules={{ required: 'La fecha es obligatoria.' }} errors={errors} minDate={new Date()} />
          </div>
          <div className="flex-1">
            <FormInput name="time" control={control} label="Hora" type="dropdown" options={timeOptions} defaultValue="" rules={{ required: 'La hora es obligatoria.' }} errors={errors} />
          </div>
        </div>
        <FormInput name="clubId" control={control} label="Club" type="dropdown" options={clubOptions} defaultValue="" rules={{ required: 'El club es obligatorio.' }} errors={errors} />
        {selectedClubId && selectedClubId.toString() === '-1' && (
          <div>
            <div className="flex-1">
              <FormInput
                name="customClubName"
                control={control}
                label="Nombre del club"
                type="text"
                defaultValue={undefined}
                rules={{
                  required: selectedClubId.toString() === '-1' ? 'El nombre del club es obligatorio.' : false
                }}
                autoComplete="off"
                errors={errors}
              />
            </div>
            <div className="flex-1">
              <FormInput
                name="customClubAddress"
                control={control}
                label="Dirección"
                type="text"
                defaultValue={undefined}
                rules={{
                  required: selectedClubId.toString() === '-1' ? 'La dirección del club es obligatoria.' : false
                }}
                autoComplete="off"
                errors={errors}
              />
            </div>
          </div>
        )}

        <FormInput
          name="courtName"
          control={control}
          label="Número/Características de cancha reservada"
          placeholder="Cancha 4. Es abierta."
          hasFloatLabel={false}
          autoComplete="off"
          type="text"
          defaultValue={undefined}
          rules={{ required: 'La cancha es obligatoria.' }}
          errors={errors}
        />
        <FormInput name="divisionId" control={control} label="Categoría" type="dropdown" options={divisionsOptions} defaultValue="" rules={{ required: 'La categoría es obligatoria.' }} errors={errors} />
        <FormInput name="courtPrice" control={control} label="Precio total de la cancha" defaultValue={undefined} hasFloatLabel={false} type="amount" rules={{ required: 'El precio es obligatorio.' }} errors={errors} />
        <Button className='primary-button' type="submit" label="Crear Partido" />
      </form>
      <ConfirmDialog />
    </div>
  );
};

export default AddMatch;
